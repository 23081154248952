// @flow
import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import { Container } from 'reactstrap'
// import { Button } from '../components/UI'
import Layout, { SEO } from '../components/Layout'

import mapAirtableData from '../lib/mapAirtableData'

type Props = {
  data: Object,
}

const InfoPage = (props: Props) => {
  const {
    data: { allAirtable },
  } = props
  const texts = mapAirtableData(allAirtable)

  return (
    <Layout>
      <SEO title={texts['Überschrift 1']} />

      <Container>
        <h1>{texts['Überschrift 1']}</h1>

        <ReactMarkdown source={texts.Einleitungstext} />

        {/* <Link to="/app/verfassen">
          <Button as="span">{texts['Call To Action Label']}</Button>
        </Link> */}
      </Container>
      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default InfoPage

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "Info" } }) {
      edges {
        node {
          recordId
          data {
            Name
            Text
          }
        }
      }
    }
  }
`
